import { ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Tooltip } from 'react-tooltip';
import Item from './Item';
const Department = ({
	departments,
	options,
	disabled = false,
	isValid = () => {},
	inputPrefix = '',
	isView = false,
}) => {
	const { translate } = useTranslations();

	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [expandMode, setExpandMode] = useState(1); // 1: Single expanding, 2: Multi expanding
	const [isExpanded, setIsExpanded] = useState(false);

	const removeSelectedDepartment = (index) => {
		setSelectedDepartments((prev) => {
			const newDepartments = [...prev];
			newDepartments.splice(index, 1);
			return newDepartments;
		});
	};

	const generateAll = () => {
		setSelectedDepartments(
			options.map((option) => {
				return {
					...option,
					isOpened: false,
					departmentsTo: options.map((option) => {
						return {
							id: option.value,
							name: option.label,
						};
					}),
				};
			}),
		);
	};

	useEffect(() => {
		if (options) {
			// Create a lookup object for options
			const optionsLookup = options.reduce((acc, option) => {
				acc[option.value] = option;
				return acc;
			}, {});

			const tempDeps = departments?.reduce((acc, department, index) => {
				const departmentOption = optionsLookup[department?.department?.id];

				if (!departmentOption) {
					return acc;
				}

				const departmentsTo = department?.departmentsTo?.reduce((acc, departmentTo) => {
					const departmentOption = optionsLookup[departmentTo?.id];

					if (!departmentOption) {
						return acc;
					}

					acc.push({
						id: departmentOption?.value,
						name: departmentOption?.label,
					});

					return acc;
				}, []);

				acc.push({
					departmentsTo,
					value: departmentOption?.value,
					label: departmentOption?.label,
					isOpened: index === 0,
				});

				return acc;
			}, []);

			setSelectedDepartments(tempDeps || []);
		}
	}, [departments, options]);

	useEffect(() => {
		if (isExpanded) {
			setExpandMode(2);
			setSelectedDepartments((prev) => {
				const newDepartments = prev.map((department) => {
					department.isOpened = true;
					return department;
				});
				return newDepartments;
			});
		} else {
			setSelectedDepartments((prev) => {
				const newDepartments = prev.map((department) => {
					department.isOpened = false;
					return department;
				});
				return newDepartments;
			});
		}

		if (expandMode === 1) {
			setSelectedDepartments((prev) => {
				const newDepartments = prev.map((department, index) => {
					if (0 === index) {
						department.isOpened = true;
					} else {
						department.isOpened = false;
					}
					return department;
				});
				return newDepartments;
			});
		}
	}, [expandMode, isExpanded]);

	const HelpMessage = (
		<div className='text-sm w-full sm:w-auto'>
			<div
				className='text-lg font-bold mb-2'
				dangerouslySetInnerHTML={{
					__html: translate('howToUseTheDepartmentSelectionFeature'),
				}}
			></div>
			<div
				className='mb-2'
				dangerouslySetInnerHTML={{
					__html: translate('dpSelectionSection1'),
				}}
			></div>
			<div
				className='mb-2'
				dangerouslySetInnerHTML={{
					__html: translate('dpSelectionSection2'),
				}}
			></div>
			<div
				className='mb-2'
				dangerouslySetInnerHTML={{
					__html: translate('dpSelectionSection3'),
				}}
			></div>
			<div
				className='mb-2'
				dangerouslySetInnerHTML={{
					__html: translate('dpSelectionSection4'),
				}}
			></div>
			<div
				className='mb-2'
				dangerouslySetInnerHTML={{
					__html: translate('dpSelectionSection5'),
				}}
			></div>
			<div
				className='italic'
				dangerouslySetInnerHTML={{
					__html: translate('dpSelectionSection6'),
				}}
			></div>
		</div>
	);

	return (
		<div className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 my-1 `}>
			<div className=' flex flex-row justify-end'>
				<div
					className='flex justify-start pr-2  text-xl  text-gray-800 hover:text-gray-600 cursor-help'
					data-tooltip-html={renderToString(HelpMessage)}
					data-tooltip-content={'test'}
					data-tooltip-id='department-tooltip'
				>
					<i class='ri-information-line'></i>
				</div>
				{!isView && selectedDepartments?.length < 2 && (
					<div
						onClick={() => generateAll()}
						className='flex  pr-2 items-center text-red-700 cursor-pointer opacity:70 hover:opacity-100'
					>
						<i class='ri-bubble-chart-line'></i>
						<span className='text-xs pl-1'>{translate('generateAll')}</span>
					</div>
				)}

				{expandMode === 2 && (
					<div
						onClick={() => {
							setIsExpanded(false);
							setExpandMode(1);
						}}
						className='flex  pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100'
					>
						<i class='ri-layout-row-fill'></i>
						<span className='text-xs pl-1'>{translate('singleExpanding')}</span>
					</div>
				)}
				{expandMode === 1 && (
					<div
						onClick={() => setExpandMode(2)}
						className='flex  pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100'
					>
						<i class='ri-layout-row-line'></i>
						<span className='text-xs pl-1'>{translate('multiExpanding')}</span>
					</div>
				)}

				{!isExpanded && (
					<div
						onClick={() => setIsExpanded(true)}
						className='flex  pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100'
					>
						<i class='ri-expand-up-down-line'></i>
						<span className='text-xs pl-1'>{translate('expandAll')}</span>
					</div>
				)}
				{isExpanded && (
					<div
						onClick={() => setIsExpanded(false)}
						className='flex justify-end pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100'
					>
						<i class='ri-contract-up-down-line'></i>
						<span className='text-xs pl-1'>{translate('collapseAll')}</span>
					</div>
				)}
			</div>

			{selectedDepartments?.map((department, index) => (
				<Item
					isView={isView}
					key={index}
					inputPrefix={inputPrefix}
					open={department.isOpened}
					index={index}
					setOpen={(isOpened) => {
						if (isOpened && expandMode === 1) {
							setSelectedDepartments((prev) => {
								const newDepartments = prev.map((department, i) => {
									if (index === i) {
										department.isOpened = true;
									} else {
										department.isOpened = false;
									}
									return department;
								});
								return newDepartments;
							});
						} else {
							setSelectedDepartments((prev) => {
								const newDepartments = prev.map((department, i) => {
									if (index === i) {
										department.isOpened = isOpened;
									}
									return department;
								});
								return newDepartments;
							});
						}
					}}
					department={department}
					disabled={disabled}
					onRemove={removeSelectedDepartment}
					isValid={(status) => {
						setSelectedDepartments((prev) => {
							const newDepartments = [...prev];
							newDepartments[index].isValid = status;
							return newDepartments;
						});

						if (selectedDepartments?.some((department) => department.isValid === false)) {
							isValid(false);
						} else {
							isValid(true);
						}
					}}
					options={options}
				/>
			))}
			{!isView && (
				<ReSelect
					disabled={disabled}
					required={selectedDepartments?.length === 0 ? true : false}
					options={options?.filter((option) =>
						selectedDepartments?.every((department) => department.value !== option.value),
					)}
					onRemove={removeSelectedDepartment}
					onSelect={(selected) => {
						setSelectedDepartments((prev) => {
							const departmentToAdd = options.filter((item) => selected === item.value)[0];

							// Check if the department is already in the array
							if (prev?.some((department) => department.value === departmentToAdd.value)) {
								// If it is, return the previous state
								return prev;
							}

							// If it's not, add the department to the array
							return [
								...prev.map((department) => ({
									...department,
									isOpened: false,
								})),
								{ ...departmentToAdd, isOpened: true },
							];
						});
					}}
				/>
			)}

			<Tooltip
				className=' w-full sm:w-auto'
				style={{
					backgroundColor: 'rgb(249 250 255)',
					color: '#0a0a0a',
					padding: '20px',
					zIndex: '9999',
					borderRadius: '10px',
					boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
				}}
				id='department-tooltip'
			/>
		</div>
	);
};

export default Department;
