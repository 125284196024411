import { Form, FormEntry } from 'components/form';
import { CheckBox, Input, MultiAddressSelector, SearchSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm } from 'hooks';
import RoomTypeService from 'modules/addresses/pages/settings/pages/RoomType/services';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates/pages/TaskTemplates/services/noParentService';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

const AutomationRuleForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();
	const roomTypeService = new RoomTypeService();
	const taskTemplateService = new TaskTemplateService();

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form
					ref={myForm}
					onSubmit={(e) => {
						e.preventDefault();
					}}
				>
					<input type='hidden' name='id' value={data?.id || false} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='description'>
						<TextArea
							isView={isView}
							placeholder={'description'}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
					<FormEntry label='roomTypes'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='taskRuleConfiguration.roomTypeIdsArray'
							service={roomTypeService}
							defaultSelected={data?.taskRuleConfiguration?.roomTypeIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='locations'>
						<MultiAddressSelector
							isView={isView}
							name='taskRuleConfiguration.locationsJson'
							addresses={data?.taskRuleConfiguration?.locations}
						/>
					</FormEntry>

					<FormEntry label='transferTemplate'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='taskRuleConfiguration.transferTemplateIdsArray'
							service={taskTemplateService}
							defaultSelected={data?.taskRuleConfiguration?.transferTemplateIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='dischargeTemplate'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='taskRuleConfiguration.dischargeTemplateIdsArray'
							service={taskTemplateService}
							defaultSelected={data?.taskRuleConfiguration?.dischargeTemplateIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default AutomationRuleForm;
